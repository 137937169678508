import { useEffect, useState } from 'react';
import styles from './avatar.module.scss';

const DefaultAvatar = `${process.env.NX_DOMAIN}/public/avatars/avatar.png`;

/* eslint-disable-next-line */
export interface AvatarProps {
  picture?: string;
}

export function Avatar(props: AvatarProps) {
  const [avatar, set_avatar] = useState<string>();
  useEffect(() => {
    if (props?.picture) {
      set_avatar(props?.picture);
    } else {
      set_avatar(DefaultAvatar);
    }
  }, [props?.picture]);
  if (avatar) {
    return <img className='w-full h-full' src={avatar} alt="Avatar" onError={() => set_avatar(DefaultAvatar)} />;
  } else {
    return <></>;
  }
}

export default Avatar;
