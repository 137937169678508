import { AdminNotification, UserNotification } from '@prisma/client';
import { createSlice } from '@reduxjs/toolkit'

export interface NotificationState {
  admin_notifications: AdminNotification[];
  read_admin_notification_ids: string[];
  user_notifications: UserNotification[];
}

const defaultState: NotificationState = {
  admin_notifications: [],
  read_admin_notification_ids: [],
  user_notifications: [],
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: defaultState,
  reducers: {
    set_admin_notifications: (state: NotificationState, action: { payload: AdminNotification[] }) => {
      state.admin_notifications = action.payload;
    },
    set_read_admin_notification_ids: (state: NotificationState, action: { payload: string[] }) => {
      state.read_admin_notification_ids = action.payload;
    },
    set_user_notifications: (state: NotificationState, action: { payload: UserNotification[] }) => {
      state.user_notifications = action.payload;
    },
    clear_notification_data: (state: NotificationState) => {
      state = defaultState;
    },
  },
})

export const {
  set_admin_notifications,
  set_read_admin_notification_ids,
  set_user_notifications,
  clear_notification_data,
} = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer