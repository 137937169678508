import styles from './ic-ring.module.scss';

/* eslint-disable-next-line */
export interface IcRingProps {
  size: number;
}

export function IcRing(props: IcRingProps) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9001 19.5V20.8C16.9001 22.954 15.154 24.7 13.0001 24.7C10.8462 24.7 9.1001 22.954 9.1001 20.8V19.5M16.9001 19.5H9.1001M16.9001 19.5H21.5678C22.065 19.5 22.3149 19.5 22.5162 19.4321C22.9008 19.3024 23.2018 19.0004 23.3315 18.6158C23.3997 18.4136 23.3997 18.163 23.3997 17.6618C23.3997 17.4424 23.3995 17.3328 23.3823 17.2282C23.3499 17.0306 23.2732 16.8433 23.1564 16.6806C23.0947 16.5947 23.0163 16.5162 22.8617 16.3616L22.3553 15.8552C22.1919 15.6918 22.1001 15.4702 22.1001 15.2392V10.4C22.1001 5.37425 18.0259 1.30004 13.0001 1.30005C7.97432 1.30006 3.9001 5.37427 3.9001 10.4V15.2392C3.9001 15.4703 3.80811 15.6918 3.64473 15.8552L3.13838 16.3616C2.98328 16.5167 2.90565 16.5946 2.84385 16.6807C2.72705 16.8433 2.64969 17.0306 2.61726 17.2282C2.6001 17.3328 2.6001 17.4425 2.6001 17.6618C2.6001 18.163 2.6001 18.4136 2.66829 18.6157C2.79802 19.0003 3.10033 19.3024 3.48496 19.4321C3.68632 19.5 3.93522 19.5 4.43252 19.5H9.1001" stroke="#E8BA73" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

IcRing.defaultProps = {
  size: 26,
}

export default IcRing;
