import classNames from 'classnames';
import styles from './plaque-notifier.module.scss';
import { Tooltip } from 'react-tooltip';

const PlaqueImg = `${process.env.NX_DOMAIN}/public/imgs/plaque.png`;

/* eslint-disable-next-line */
export interface PlaqueNotifierProps {
  className?: string;
  onClick?: () => void;
}

export function PlaqueNotifier(props: PlaqueNotifierProps) {
  return (
    <>
      <div className={classNames(
        styles['container'],
        'animate-pulse',
        props.className
      )} data-tooltip-id="earned-plaque-icon" onClick={props.onClick}>
        <img className='w-4 h-4' src={PlaqueImg} alt="Plaque" />
      </div>
      <Tooltip id="earned-plaque-icon">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          You have earned plaques from the Refer Friends program!
        </div>
      </Tooltip>
    </>
  );
}

export default PlaqueNotifier;
