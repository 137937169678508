import styles from './ic-right-1.module.scss';

/* eslint-disable-next-line */
export interface IcRight1Props {
  size: number;
  color: string;
}

export function IcRight1(props: IcRight1Props) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Arrow / Arrow_Right_LG">
        <path id="Vector" d="M21 12L16 7M21 12L16 17M21 12H3" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
}

IcRight1.defaultProps = {
  size: 24,
  color: 'white'
}

export default IcRight1;
