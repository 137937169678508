import { AxiosError, AxiosInstance } from "axios";
import { CBItemPrice, CBPaymentSource } from "@prisma/client";
import {
    HostedPage,
} from 'chargebee-typescript/lib/resources';
import { ISubscriptionWithSubscriptionItems } from "@clc-v2/utilities";
import api from "./_api";

class PaymentService {
    private api: AxiosInstance | undefined
    constructor(_api?: AxiosInstance) {
        this.api = _api;
    }
    async manageSubscription() {
        return new Promise<string>((resolve, reject) => {
            this.api?.post(`/cb-payment/manage-subscription`).then((res: { data: string }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getCurrentSubscription() {
        return new Promise<ISubscriptionWithSubscriptionItems>((resolve, reject) => {
            this.api?.get(`/cb-payment/current-subscription`).then((res: { data: ISubscriptionWithSubscriptionItems }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getPriceItemPrices() {
        return new Promise<CBItemPrice[]>((resolve, reject) => {
            this.api?.get(`/cb-payment/item-prices`).then((res: { data: CBItemPrice[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getGiftableItemPrices() {
        return new Promise<CBItemPrice[]>((resolve, reject) => {
            this.api?.get(`/cb-payment/giftable-item-prices`).then((res: { data: CBItemPrice[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async generateCheckoutUrl(item_price_id: string, user_id: string, coupon_id?: string | null) {
        return new Promise<HostedPage>((resolve, reject) => {
            this.api?.post(`/cb-payment/generate-checkout-newurl`, {
                user_id: user_id,
                item_price_id: item_price_id,
                coupon_id: coupon_id,
            }).then((res: { data: HostedPage }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getPaymentSource(customer_id: string) {
        return new Promise<CBPaymentSource>((resolve, reject) => {
            this.api?.get(`/cb-payment/get-primary-payment-source?customer_id=${customer_id}`).then((res: { data: CBPaymentSource }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async reactivateSubscription(customer_id: string, subscription_id: string) {
        return new Promise<number>((resolve, reject) => {
            this.api?.post(`/cb-payment/reactivate-subscription`, {
                customer_id,
                subscription_id
            }).then((res: { data: number }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async generateGiftCheckoutUrl(item_price_id: string) {
        return new Promise<HostedPage>((resolve, reject) => {
            this.api?.post(`/cb-payment/generate-gift-checkout-newurl`, {
                item_price_id: item_price_id,
            }).then((res: { data: HostedPage }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async updatePlan(item_price_id: string) {
        return new Promise((resolve, reject) => {
            this.api?.post(`/cb-payment/update-plan`, {
                item_price_id
            }).then((res: { data: any }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async generateChangePaymentMethodUrl(redirect_url: string) {
        return new Promise<HostedPage>((resolve, reject) => {
            this.api?.post(`/cb-payment/generate-change-payment-method-newurl`, { redirect_url }).then((res: { data: HostedPage }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
}

export const paymentService = new PaymentService(api);
