import classNames from 'classnames';
import styles from './pick-path-topic-card.module.scss';
import Card from '../card/card';
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import { useEffect, useRef, useState } from 'react';
import lodash from 'lodash';
import { Lesson } from '@prisma/client';
import Divider from '../divider/divider';
import { IcPlay, IcPlay1 } from '@clc-v2/icons';
import { useDraggable } from 'react-use-draggable-scroll';
const preflopImg = `${process.env.NX_DOMAIN}/public/imgs/topics/preflop.png`;
const flopImg = `${process.env.NX_DOMAIN}/public/imgs/topics/flop.png`;
const turnImg = `${process.env.NX_DOMAIN}/public/imgs/topics/turn.png`;
const riverImg = `${process.env.NX_DOMAIN}/public/imgs/topics/river.png`;
const strategyImg = `${process.env.NX_DOMAIN}/public/imgs/topics/strategy.png`;
// const masteredBadgeImg = `${process.env.NX_DOMAIN}/public/imgs/mastered-badge.png`;
const masteredImg = `${process.env.NX_DOMAIN}/public/imgs/topics/mastered.png`;

/* eslint-disable-next-line */
export interface PickPathTopicCardProps {
  // topic: 'preflop' | 'flop' | 'turn' | 'river' | 'strategy' | 'other';
  index: number;
  title: String;
  required_master_level: number; // binary number
  lessons: Lesson[];
  mastered_lesson_uids: string[];
  on_lesson_click_handler: (lesson_uid: string) => Promise<boolean>;
}

export function PickPathTopicCard(props: PickPathTopicCardProps) {
  const [loading, set_loading] = useState<boolean>(false);
  const [display_count, set_display_count] = useState<number>(0);
  const [opened_topic, set_opened_topic] = useState<boolean>(false);
  const ref = useRef<HTMLElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  const on_topic_click_handler = lodash.debounce(() => {
    set_opened_topic((_prev) => !_prev);
  }, 300);
  const on_lesson_click_handler = lodash.debounce((lesson_uid: string) => {
    set_loading(true);
    props.on_lesson_click_handler(lesson_uid).then(() => set_loading(false));
  }, 300);
  useEffect(() => {
    let timer: NodeJS.Timer;
    if (props.mastered_lesson_uids.length !== display_count) {
      if (props.mastered_lesson_uids.length > display_count) {
        timer = setTimeout(() => set_display_count((_prev) => _prev + 1), 80);
      } else if (props.mastered_lesson_uids.length < display_count) {
        timer = setTimeout(() => set_display_count((_prev) => _prev - 1), 80);
      }
    }
    return () => clearTimeout(timer);
  }, [props.mastered_lesson_uids.length, display_count]);
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (ref.current) {
        // Check if the mouse is within the container
        const isMouseOverContainer = ref.current.contains(e.target as Node);

        // If the mouse is over the container, prevent default scroll behavior
        if (isMouseOverContainer) {
          e.preventDefault();

          // Adjust scrollLeft of the container based on the wheel delta
          ref.current.scrollLeft += e.deltaY;
        }
      }
    };

    // Add wheel event listener
    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      // Clean up event listener when component unmounts
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);
  return (
    <div className={classNames(
      'flex flex-col lg:flex-row items-center lg:items-end gap-6',
      {
        'w-full': opened_topic,
        'w-fit': !opened_topic
      },
      'relative'
    )}>
      <div className={classNames(
        styles['container'],
        'max-w-[calc(100vw-32px)] md:max-w-[calc(100vw-80px)] w-96 h-72',
        'relative',
        'grow-0 shrink-0',
        {
          'cursor-pointer': !loading
        },
        'group'
      )} onClick={on_topic_click_handler}>
        {
          props.title.toLocaleLowerCase() === 'preflop' ? (
            <img className='absolute z-20 left-1/2 top-0 transform -translate-x-1/2 h-32' src={preflopImg} alt="Preflop" />
          ) : (
            props.title.toLocaleLowerCase() === 'flop' ? (
              <img className='absolute z-20 left-1/2 top-0 transform -translate-x-1/2 h-32' src={flopImg} alt="Preflop" />
            ) : (
              props.title.toLocaleLowerCase() === 'turn' ? (
                <img className='absolute z-20 left-1/2 top-0 transform -translate-x-1/2 h-32' src={turnImg} alt="Preflop" />
              ) : (
                props.title.toLocaleLowerCase() === 'river' ? (
                  <img className='absolute z-20 left-1/2 top-0 transform -translate-x-1/2 h-32' src={riverImg} alt="Preflop" />
                ) : (
                  props.title.toLocaleLowerCase() === 'strategy' ? (
                    <img className='absolute z-20 left-1/2 top-0 transform -translate-x-1/2 h-32' src={strategyImg} alt="Preflop" />
                  ) : (
                    <img className='absolute z-20 left-1/2 top-0 transform -translate-x-1/2 h-32' src={strategyImg} alt="Preflop" />
                  )
                )
              )
            )
          )
        }
        <Card size='small' className='top-16 z-10 absolute w-full h-56 transition-all duration-200 hover:border-primary-golden backdrop-blur bg-gradient-radial from-color-4 to-secondary-coal'>
          <h2 className='absolute left-5 top-20 text-2xl font-medium text-white'>{props.title}</h2>
          <div className='absolute left-5 bottom-5 flex items-center gap-2'>
            <div className={classNames(
              'w-9 h-9',
              'rounded-full',
              'border border-solid border-color-2',
              'text-text-op70 text-xs leading-[12px] font-inter font-normal',
              'flex justify-center items-center'
            )}>
              {/* {props.required_master_level} */}
              {props.index.toString().padStart(2, '0')}
            </div>
            <div className='flex flex-col text-secondary-grey font-light'>
              <span className='uppercase text-[10px] leading-[12px] tracking-[2px] font-inter'>Recommended</span>
              <span className='uppercase text-[10px] leading-[12px] tracking-[2px] font-inter'>Skill Level</span>
            </div>
          </div>
          <div className='absolute right-5 bottom-5 w-[76px] h-[76px]'>
            <CircularProgressbar
              classes={{
                root: 'root CircularProgressbar ',
                trail: 'CircularProgressbar-trail ' + styles['progressbar-trail'],
                background: 'background',
                path: 'path CircularProgressbar-path shadow-md ' + styles['progressbar-path'],
                text: 'text CircularProgressbar-text ' + styles['progressbar-text']
              }}
              value={display_count / (props.lessons.length ?? 1) * 100}
              text={`${Math.round(display_count / props.lessons.length * 100)} %`}
              strokeWidth={6}
              styles={buildStyles({
                textColor: "rgba(255, 255, 255, 0.7)",
                pathColor: "rgba(232, 186, 115, 1)",
                trailColor: "rgba(60, 55, 56, 1)"
              })}
            />
          </div>
        </Card>
        <Card size='small' className={classNames(
          'absolute bottom-2 transition-all duration-200 border-[1px] hover:border-primary-golden',
          'right-0 opacity-0 group-hover:-right-20 group-hover:opacity-100',
          'hidden lg:block',
          {
            '!hidden': opened_topic
          }
        )}>
          <div className='w-48 flex flex-col'>
            <p className='uppercase text-sm text-secondary-grey'>Lesson 3333</p>
            <p className='text-white text-xl h-14 line-clamp-2'>Test Lesson</p>
            <div className='w-full py-1'>
              <Divider />
            </div>
            <div className='flex items-center gap-2'>
              <span className='line-clamp-2 grow shrink text-secondary-grey text-xs'>Test Description</span>
              <button className='grow-0 shrink-0 w-14 h-14 rounded-full flex justify-center items-center bg-gradient-to-br from-color-2 to-color-3 cursor-pointer'>
                <IcPlay />
              </button>
            </div>
          </div>
        </Card>
      </div>
      <div className={classNames(
        'w-full lg:w-fit flex items-end gap-2',
        'grow shrink',
        'overflow-auto',
        {
          'hidden': !opened_topic,
        },
        'hide-scrollbar pb-1'
      )} ref={ref} {...events}>
        {
          props.lessons.map((lesson, id) => (
            <Card size='small' className='relative transition-all duration-200 border-[1px] hover:border-primary-golden !bg-[#15171A]' key={id} onClick={() => on_lesson_click_handler(lesson.uid)}>
              {(props.mastered_lesson_uids.indexOf(lesson.uid) >= 0) && (
                <img title={`Mastered lesson "${lesson.name}"`} className='absolute right-0 top-0 w-1/2' src={masteredImg} alt='Mastered' />
                // <span className='absolute top-4 right-0 px-1 py-0 bg-green animate-pulse text-white rounded-md rotate-[30deg]'>Mastered</span>
              )}
              <div className='w-48 flex flex-col'>
                <p className='uppercase text-sm text-secondary-grey'>Lesson {id + 1}</p>
                <p className='text-white text-xl h-14 line-clamp-2'>{lesson.name}</p>
                <div className='w-full py-1'>
                  <Divider />
                </div>
                <div className='flex items-center gap-2'>
                  <span title={lesson.description} className='line-clamp-2 grow shrink text-secondary-grey text-xs'>{lesson.description}</span>
                  <button className='grow-0 shrink-0 w-14 h-14 rounded-full flex justify-center items-center bg-gradient-to-br from-color-2 to-color-3 cursor-pointer'>
                    <IcPlay1 color='rgba(232, 186, 115, 1)' />
                  </button>
                </div>
              </div>
            </Card>
          ))
        }
      </div>
    </div>
  );
}

export default PickPathTopicCard;
